import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"

import { GatsbyImage } from "gatsby-plugin-image"

const Template = ({data}) => {
    const markdown = data.markdownRemark
    return (
        <Layout>
            <Seo title={markdown.frontmatter.title + ' | Case Studies'}/>
            <Container>
                <h1><small>{markdown.frontmatter.title}</small></h1>
                <p>
                    {/* <Img 
                        fluid={markdown.frontmatter.Image01.childImageSharp.fluid}
                        alt={markdown.frontmatter.image1alt}
                        className="img-fluid"
                    />  */}
                    <GatsbyImage 
          image={markdown.frontmatter.Image01.childImageSharp.gatsbyImageData} 
          alt={markdown.frontmatter.title}
        />
                </p>
                <Row>
                    <Col lg={3}>
                        <p><strong>Project Type:</strong><br />
                        {markdown.frontmatter.projectType}
                        </p>
                        <p><strong>Location:</strong><br />
                        {markdown.frontmatter.location}
                        </p>
                        <p><strong>Result:</strong><br />
                        {markdown.frontmatter.result}
                        </p>
                    </Col>
                    <Col lg={9}>
                        <span dangerouslySetInnerHTML={{ __html: markdown.html}} />
                    </Col>
                </Row>
                
            </Container>
        </Layout>
    )
}

export default Template

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug} }) {
            html
            frontmatter {
                title
                image1alt
                projectType
                location
                result
                Image01 {
                    childImageSharp {
                      gatsbyImageData(
                        webpOptions: {quality: 30}
                        placeholder: BLURRED
                        jpgOptions: {quality: 50, progressive: false}
                      )
                    }
                  }
            }
        }
    }
`